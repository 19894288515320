const i18n = require('../i18n/i18n')

const toLink = (lang, type, slug) => {
  const realLang = lang.split('-')[0]

  if (slug && type) {
    return `/${realLang}/${i18n[realLang][type].slug}/${slug}`
  } else if (type) {
    return `/${realLang}/${i18n[realLang][type].slug}`
  } else {
    return `/${realLang}`;
  }
}

module.exports = {
  toLink
}
