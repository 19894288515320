import React from 'react'
import { Helmet } from 'react-helmet'

import { graphql } from 'gatsby'

import Image from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Hero from '../components/hero'

import { toLink } from '../util/link-helper'

import { getTitle } from '../util/title-helper'

import './styles/frontpage.css'

const Frontpage = ({ data: { frontPage }, pageContext }) => {
  const { data } = frontPage

  const lang = pageContext.lang.split('-')[0]

  const announcement = data.announcement ? (
    <div
      className="frontpage__announcement"
      dangerouslySetInnerHTML={{ __html: data.announcement.html }}
    />
  ) : null

  return (
    <Layout page="frontpage">
      <Helmet>
        <title>{getTitle(lang)}</title>
      </Helmet>

      <SEO title={getTitle(lang)} lang={lang} />
      <Hero
        slogan={data.slogan.text}
        videoId={data.video_id}
        fallbackImageUrl={data.fallbackheroimage.url}
      />

      <div className="frontpage__content">
        <div className="frontpage__highlights">
          {announcement ? announcement : null}

          {data.highlights.map((highlight, i) => (
            <a
              key={`highlight-${i}`}
              className="frontpage__highlight"
              href={toLink(
                frontPage.lang,
                highlight.link ? highlight.link.type : null,
                highlight.link ? highlight.link.uid : null
              )}
            >
              <Image
                className="frontpage__highlight__part frontpage__highlight__picture"
                fluid={highlight.picture.localFile.childImageSharp.fluid}
              />
              <div className="frontpage__highlight__part frontpage__highlight__text">
                <h2>{highlight.title.text}</h2>
                <div
                  dangerouslySetInnerHTML={{ __html: highlight.text.html }}
                />
              </div>
            </a>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default Frontpage

export const pageQuery = graphql`
  query FrontpageBySlug($uid: String!, $lang: String!) {
    frontPage: prismicFrontpage(uid: { eq: $uid }, lang: { eq: $lang }) {
      uid
      lang
      data {
        announcement {
          html
        }
        slogan {
          text
        }
        video_id
        fallbackheroimage {
          url
        }
        highlights {
          link {
            type
            uid
          }
          text {
            html
          }
          title {
            text
          }
          picture {
            url
            localFile {
              childImageSharp {
                fluid(maxWidth: 800, srcSetBreakpoints: [800]) {
                  src
                  srcSet
                  aspectRatio
                  sizes
                }
              }
            }
          }
        }
      }
    }
  }
`
