import React from "react"
import styled from "styled-components"

const HeroWrapper = styled.div`
  position: relative;
  overflow: hidden;

  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;

  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
`

const Video = styled.iframe`
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: 177.77777778vh;
  height: 56.25vw  ;
  transform: translateX(-50%) translateY(-50%);
  border: 0;
`

const Hero = props => (
  <HeroWrapper style={{ backgroundImage: `url(${props.fallbackImageUrl})` }}>
    <Video
      src={`https://player.vimeo.com/video/${props.videoId}?background=1&autoplay=1&loop=1&byline=0&title=0&controls=0&muted=1&app_id=122963`}
      frameborder="0"
      allow="autoplay; fullscreen"
      allowfullscreen=""
      title="Niemi-Kapee" />

    <h1 className="slogan">{props.slogan}</h1>
  </HeroWrapper>
)

export default Hero
